import { Container } from "@util/standard";
import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import ComparePlansGrid from "@components-v3/ComparePlansGrid";
import { GlobalStyle } from "../styles/globalStyles";
import { useLocation } from "@reach/router";

const CompareEmbed = () => {
  const location = useLocation();
  const [isShowTrailingCtas, setIsShowTrailingCtas] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setIsShowTrailingCtas(params.get("show_trailing_ctas") === "true");
  }, [location.search]);


  useEffect(() => {
    const sendHeight = () => {
      window.parent.postMessage(
        { type: "IFRAME_HEIGHT", height: document.documentElement.scrollHeight },
        "*"
      );
    };

    // Send height initially
    sendHeight();

    // Listen for resize events
    window.addEventListener("resize", sendHeight);

    // Send height whenever the content changes
    const observer = new MutationObserver(sendHeight);
    observer.observe(document.body, { childList: true, subtree: true });

    // Listen for explicit height requests from the parent
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "REQUEST_HEIGHT") {
        sendHeight();
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", sendHeight);
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <BodyStyle />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
          width: "100%",
        }}
        margin="auto"
      >
        {/* This is hard coded because the entire compare grid is static anyway */}
        <h2 style={{ textAlign: "center", marginBottom: "40px", fontSize: "35px", lineHeight: "normal" }}>
          Discover how features differ between plans.
        </h2>
        <ComparePlansGrid isAddTrailingCta={isShowTrailingCtas} />
      </Container>
    </>
  );
};

const BodyStyle = createGlobalStyle`
body {
  background-color: transparent !important;
}
`;

export default CompareEmbed;

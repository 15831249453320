import {
  SanityBlogs,
  SanityBlogsBlock,
  SanityBlogsBlockOrButtonsOrCardOrCareersBlockOrCarouselBlockOrChecklistBlockOrColumnV2OrCustomBlockContentOrEmbedBlockOrHubspotFormBlockOrImageAboveTextBlockOrImageWithMetaOrLogosBlockOrRowOrSocialsBlockOrTestimonialV2OrTextAndGridBlockOrVideoBlockV2,
  SanityBlogV2,
  SanityButtons,
  SanityCard,
  SanityCareersBlock,
  SanityCarouselBlock,
  SanityChecklistBlock,
  SanityColumnV2,
  SanityCustomBlockContent,
  SanityEmbedBlock,
  SanityHubspotFormBlock,
  SanityImage,
  SanityImageAboveTextBlock,
  SanityImageWithMeta,
  SanityLink,
  SanityLogosBlock,
  SanityNavigationLinkOrNavigationMenu,
  SanityNavigationMenu,
  SanityNestedLinks,
  SanityRow,
  SanitySocialsBlock,
  SanityTextAndGridBlock,
  SanityVideoBlockV2,
} from "../../graphql-types";
import { SanityTestimonialV2 } from "./../../graphql-types";
// @ts-expect-error ts-migrate(2305) FIXME: Module '"./constants"' has no exported member 'ass... Remove this comment to see the full error message
import { assets, buttonStyle, colors } from "./constants";

export type Dimensions = {
  width?: string;
  height?: string;
};
export type FontFam = "header" | "header bold" | "header light";
export type FontWeight = "bold" | "normal" | "light" | number;
export type FlexDirection =
  | "row"
  | "column"
  | "row-reverse"
  | "column-reverse"
  | "initial";
export type FlexWrap = "nowrap" | "wrap" | "wrap-reverse" | "initial";
export type JustifySelf =
  | "center"
  | "start"
  | "end"
  | "flex-start"
  | "flex-end"
  | "self-start"
  | "self-end"
  | "left"
  | "right";
export type JustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-around"
  | "space-between"
  | "space-evenly"
  | "initial";
export type AlignItems =
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "initial";
export type AlignSelf =
  | "auto"
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "initial";
export type Overflow = "auto" | "scroll" | "visible" | "hidden" | "initial";
export type PDisplayType = "inline" | "block" | "flex" | "initial";
export type Cursor = "grab" | "pointer" | "auto" | "initial";
export type WhiteSpace =
  | "initial"
  | "normal"
  | "nowrap"
  | "pre-line"
  | "pre-wrap";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ButtonTheme = keyof typeof buttonStyle;

//type checking
export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}

export function isSanityNestedLinks(data: any): data is SanityNestedLinks {
  return data._type === "nestedLinks";
}

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export const isSanityNavigationMenu = (
  value: SanityNavigationLinkOrNavigationMenu
): value is SanityNavigationMenu => {
  return value._type === "navigationMenu";
};

export const isBlogV2 = (
  blog: SanityBlogs | SanityBlogV2
): blog is SanityBlogV2 => {
  return blog._type === "blogV2";
};

export type SanityBlockType = SanityBlogsBlockOrButtonsOrCardOrCareersBlockOrCarouselBlockOrChecklistBlockOrColumnV2OrCustomBlockContentOrEmbedBlockOrHubspotFormBlockOrImageAboveTextBlockOrImageWithMetaOrLogosBlockOrRowOrSocialsBlockOrTestimonialV2OrTextAndGridBlockOrVideoBlockV2;

export type RenderBlockFunction = (b: SanityBlockType) => JSX.Element;

export const blockTypes: BlockType[] = [
  "checklistBlock",
  "hubspotFormBlock",
  "logosBlock",
  "socialsBlock",
  "customBlockContent",
  "imageWithMeta",
  "careersBlock",
  "row",
  "videoBlockV2",
  "textAndGridBlock",
  "imageAboveTextBlock",
  "card",
  "buttons",
  "columnV2",
  "testimonialV2",
  "blogsBlock",
  "carouselBlock",
  "embedBlock",
  "link",
  "span",
  "block",
  "seo",
];

//span, seo, block, link

export type BlockType =
  | "span"
  | "block"
  | "seo"
  | "link"
  | "checklistBlock"
  | "hubspotFormBlock"
  | "logosBlock"
  | "socialsBlock"
  | "customBlockContent"
  | "imageWithMeta"
  | "careersBlock"
  | "row"
  | "videoBlockV2"
  | "textAndGridBlock"
  | "imageAboveTextBlock"
  | "card"
  | "buttons"
  | "columnV2"
  | "testimonialV2"
  | "blogsBlock"
  | "carouselBlock"
  | "embedBlock";

type BlockTypeToBlock<T extends BlockType> = T extends "checklistBlock"
  ? SanityChecklistBlock
  : T extends "hubspotFormBlock"
  ? SanityHubspotFormBlock
  : T extends "logosBlock"
  ? SanityLogosBlock
  : T extends "socialsBlock"
  ? SanitySocialsBlock
  : T extends "customBlockContent"
  ? SanityCustomBlockContent
  : T extends "imageWithMeta"
  ? SanityImageWithMeta
  : T extends "careersBlock"
  ? SanityCareersBlock
  : T extends "row"
  ? SanityRow
  : T extends "videoBlockV2"
  ? SanityVideoBlockV2
  : T extends "textAndGridBlock"
  ? SanityTextAndGridBlock
  : T extends "imageAboveTextBlock"
  ? SanityImageAboveTextBlock
  : T extends "card"
  ? SanityCard
  : T extends "buttons"
  ? SanityButtons
  : T extends "columnV2"
  ? SanityColumnV2
  : T extends "testimonialV2"
  ? SanityTestimonialV2
  : T extends "blogsBlock"
  ? SanityBlogsBlock
  : T extends "carouselBlock"
  ? SanityCarouselBlock
  : T extends "embedBlock"
  ? SanityEmbedBlock
  : never;

export type MapBlockFunctions<T, R> = {
  [P in BlockType]?: (block: BlockTypeToBlock<P>) => R;
} & {
  /** Default handler if no other matching handler is specified. */
  fallback?: (block: T) => R;
};

export const isBlockType = <T extends SanityBlockType>(
  block: T,
  type: BlockType
): block is T => {
  return block._type === type;
};

export const getBlockType = (block: SanityBlockType): BlockType | null => {
  return blockTypes.find(type => isBlockType(block, type)) ?? null;
};

export const mapBlock = <T extends SanityBlockType, R = JSX.Element>(
  block: T,
  fns: MapBlockFunctions<T, R>
): R | null => {
  const blockType = getBlockType(block);
  if (blockType === null)
    throw new Error(`Invalid block type: ${JSON.stringify(block, null, 2)}`);
  return (fns[blockType] ?? fns.fallback)?.(block) ?? null;
};

export enum SubscriptionTier {
  Free = "Free",
  Business = "Business",
  Enterprise = "Enterprise",
  Growth = "Growth",
  BusinessPro = "BusinessPro",
  BusinessV2 = "BusinessV2",
}